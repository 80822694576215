import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';

import TabActors from "./TabActors";
import TabDialogs from "./TabDialogs";
import TabText from "./TabText";
import CardProcess from "../../../app/components/CardProcess";
import TabDataForm from "./TabDataForm";

import GeneralService from "../../../services/GeneralService";
import { setSelectedForm } from "../../../reducers/forms/reducerFormSlice";
import { cleanAll, loadAll } from "../../../reducers/forms/reducerCreateProcessForm";
import WelcomeMessage from "./WelcomeMessage";
import { useResize } from "../../../app/hooks/useResize";
import { loadInformation } from "../utils/persistence";
import { LogVisitor } from "../../../app/components/LogVisitor";

const FillOutFormView = () => {
    const { id = 0 } = useParams();
    const [currentStep, setCurrentStep] = useState(1);
    const dispatch = useDispatch();
    const { windowSize } = useResize();

    const loadForm = async () => {
        const service = new GeneralService("form/public/" + id);
        const res = await service.getRequest();
        dispatch(setSelectedForm({ value: res }));

        dispatch(loadAll(loadInformation(res.id)));
    }

    useEffect(() => {
        loadForm();
    }, [id]);

    const steps = <>
        <CardProcess
            icon="fa-tachograph-digital text-orange"
            iconColor="text-orange"
            title="Formulario"
            subtitle="Diligencia los datos generales del formulario y sigue con el proceso"
            isActive={currentStep === 1}
            order={1}
            className="rounded-start"
            onClick={() => setCurrentStep(1)}
        />
        <CardProcess
            icon="fa-users"
            iconColor="text-purple"
            title="Personas"
            subtitle="Número de personas que participan en la ejecución del documento"
            isActive={currentStep === 2}
            order={2}
            icon_style="bg-orange text-white"
            onClick={currentStep > 2 ? () => setCurrentStep(2) : undefined}
        />
        <CardProcess
            icon="fa-question"
            iconColor="text-green"
            title="Diálogos"
            subtitle="Configura la forma de envío y firma de tus clientes"
            isActive={currentStep === 3}
            order={3}
            icon_style="bg-green text-white"
            onClick={currentStep > 3 ? () => setCurrentStep(3) : undefined}
        />
        <CardProcess
            icon="fa-envelope-open-text text-blue"
            iconColor="text-blue"
            title="Texto"
            subtitle="Redacción del contenido general del documento y sus respectivos códigos"
            isActive={currentStep === 4}
            order={4}
            icon_style="bg-purple text-white"
            className="rounded-end"
        />
    </>

    return (
        <>
            <div className="pt-2 bg-light-purple pb-5" style={{ display: "flex", minHeight: "100vh" }}>
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-11 col-sm-11 col-md-10 col-lg-10 mx-auto">
                            <WelcomeMessage />

                            <div className="row rounded-start">
                                {
                                    windowSize.width <= 576
                                        ?
                                        <div className="col-12 abs-center">
                                            {steps}
                                        </div>
                                        :
                                        steps
                                }

                            </div>

                            <div className="row">
                                <div className="col-12 p-0">
                                    {currentStep === 1 && <TabDataForm setCurrentStep={setCurrentStep} />}
                                    {currentStep === 2 && <TabActors setCurrentStep={setCurrentStep} />}
                                    {currentStep === 3 && <TabDialogs setCurrentStep={setCurrentStep} />}
                                    {currentStep === 4 && <TabText />}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <LogVisitor />
        </>
    );
}

export default FillOutFormView;