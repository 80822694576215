import { useRef } from 'react';
import { useSelector } from 'react-redux';

import SelectCategory from "./SelectCategory";
import PeopleClusters from "./peopleClusters";

import { useRegisterCustomer } from '../hooks/useRegisterCustomer';
import FillForm from "../../../../assets/fill-form.svg";
import AccordionCategory from './AccordionCategory';

const RegisterCustomer = () => {
    const { selectedTypepeople, listCategories } = useSelector(state => state.typepeopleSlice);
    const { name, encodeDocument, saveDataPeople } = useRegisterCustomer();
    const refForm = useRef();

    return (
        <div className="pt-4 bg-light-purple pb-5" style={{ display: "flex", minHeight: "100vh" }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-11 col-sm-11 col-md-10 col-lg-10 mx-auto">

                        <div className="row bg-green rounded-3">
                            <div className="col-md-12 col-lg-2 abs-center">
                                <img className="ms-2" src={FillForm} alt="Fill form" />
                            </div>
                            <div className="col-md-12 col-lg-10 p-3">
                                <h4>Registra aquí tus datos <i className="fa-solid fa-arrow-down animate__animated animate__bounce animate__repeat-3 ms-3"></i></h4>
                                <p><b>¡Hola {name.split(" ")[0]}!</b> Gracias por aceptar esta invitación. Nos gustaría conocerte mejor y ofrecerte una experiencia personalizada. Para ello, te pedimos que nos proporciones algunos datos personales de acuerdo con el formulario. Tus datos estarán protegidos y no los compartiremos con nadie sin tu consentimiento. Al registrarte, podemos ejecutar los procesos siguientes con tu administrador de la empresa. Además, podrás recibir información relevante y actualizada sobre nuestros productos y servicios. <b>¡Te lo agradeceremos!</b> 😊</p>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12 col-lg-8">
                                <div className="row">
                                    <div className="col-12 col-lg-4 pt-1 text-center">
                                        <i className="fa-brands fa-wpforms fs-4 me-1"></i>
                                        <h6 className="d-inline-block">Formulario {selectedTypepeople.name}</h6>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="row">
                                            <SelectCategory />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col">

                                <form ref={refForm} className="p-0" onSubmit={saveDataPeople}>
                                    <PeopleClusters id={0} refForm={refForm} />
                                    <hr className="mt-4" />
                                    {
                                        listCategories?.length !== 0 &&
                                        <>
                                            <h4 className="text-center mb-1 mt-3">
                                                <strong>Categorías</strong>
                                            </h4>
                                            {
                                                listCategories.map((category) =>
                                                    <AccordionCategory
                                                        key={category.id}
                                                        category={category}
                                                        encodeDocument={encodeDocument}
                                                        id={0}
                                                    />)
                                            }
                                        </>
                                    }
                                    {
                                        Object.keys(selectedTypepeople).length !== 0 && selectedTypepeople.clusters !== undefined
                                        &&
                                        <div className="row">
                                            <div className="col">
                                                <button type="submit"
                                                    className="btn btn-success d-block rounded-3 ms-auto mt-3 px-3 py-2">
                                                    <i className="fa-solid fa-paper-plane me-2"></i> Enviar
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </form >
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );

}
export default RegisterCustomer;