import { ProgressBar } from 'react-bootstrap'

const UsagePercentageCard = ({ title = "", icon = "", total = 1, used = 0 }) => {
    let now = (used / total) * 100;
    now = now.toFixed(1);

    return (
        <div className="usage__percentage-card font-lato">
            <div className="row">
                <div className="col-9 mt-3 center-vertical">
                    <i className={`${icon} me-2 fs-5`}></i>
                    <span className='fs-7'></span>
                    {title}
                </div>
                <div className="col">
                    <div className="usage__percentage-counter">
                        {now} %
                    </div>
                </div>
            </div>
            <div className="row px-4">
                <div className="col text-center fw-bold fs-8">
                    {used}/{total}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col pe-4">
                    <ProgressBar
                        animated
                        striped
                        variant={now < 90 ? "info": now >= 90 && now <= 98 ? "warning":"danger"}
                        now={now}
                        label={`${now}%`}
                        style={{height: "10px"}}
                    />
                </div>
            </div>
        </div>
    )
}

export default UsagePercentageCard;