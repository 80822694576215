import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LogViews } from "./components/LogViews";
import CustomDoughnut from "./components/CustomDoughnut";
import UsagePercentageCard from "./components/UsagePercentageCard";

import GeneralService from "../../services/GeneralService";
import { setCompanyMetrics } from "../../reducers/business/reducerCompanyMetrics";
import Metrics from "../../assets/metrics.svg";
import "./styles/dashboard_styles.css";

const DashboardView = () => {
    const { company } = useSelector(state => state.businessSlice);
    const { metrics } = useSelector(state => state.companyMetricsSlice);
    const { plan } = company;
    const dispatch = useDispatch();

    const loadMetrics = async () => {
        const service = new GeneralService("business-metrics");
        const { metrics = [] } = await service.getList(1000);
        dispatch(setCompanyMetrics({ value: metrics }));
    }

    useEffect(() => {
        loadMetrics();
    }, []);

    return (
        <div className="py-1 px-3 max-height-overflow-y">
            <div className="welcome-block">
                <div className="row">
                    <div className="col-7 px-5">
                        <div className="row">
                            <div className="col">
                                <h2 className="text-white" style={{ paddingTop: "1em" }}>Bienvenido</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="text-white mt-1">
                                    Como miembro de <b>{company.name}</b> puedes ver los consumos respecto al mes actual.

                                    {
                                        !company.is_system_admin ?
                                            " Recuerda que si se sobrepasan los límites es posible actualizar a un nuevo plan."
                                            :
                                            " Desde aquí puedes monitorear todas las métricas de acuerdo con los gastos globales los cuales incluyen los de diversas empresas vinculadas."
                                    }

                                </p>
                            </div>
                        </div>
                        {
                            !company.is_system_admin &&
                            <div className="row">
                                <div className="col">
                                    <button className="btn text-white rounded-pill py-2 px-3 mt-2 mb-2" style={{ backgroundColor: "#ff8000" }}>
                                        <i className="fa-solid fa-crown me-2"></i>Actualizar plan
                                    </button>
                                </div>
                            </div>
                        }

                    </div>
                    <div className="col">
                        <img src={Metrics} className="mt-3" style={{ width: "200px" }} alt="" />
                    </div>
                </div>
            </div>

            {
                plan &&
                <div className="row mt-5">
                    <div className="col-12 col-lg-8 rounded-5 px-4">
                        <h5 className="font-lato mb-4 fw-bold">Consumos</h5>
                        <div className="row">
                            <div className="col-12 col-lg-3">
                                <CustomDoughnut />
                            </div>
                            <div className="col-12 col-lg-9">
                                <div className="row usage__percentage">
                                    <div className="col-12 col-lg-6 item__usage">
                                        <UsagePercentageCard
                                            icon="fa-solid fa-envelope"
                                            title="Emails"
                                            total={plan.email_quantity}
                                            used={metrics.length > 0 && metrics[0].sent_emails}
                                        />
                                    </div>

                                    <div className="col-12 col-lg-6 item__usage">
                                        <UsagePercentageCard
                                            icon="fa-solid fa-file"
                                            title="Documentos"
                                            total={plan.document_quantity}
                                            used={metrics.length > 0 && metrics[0].generated_documents}
                                        />
                                    </div>

                                    <div className="col-12 col-lg-6 item__usage">
                                        <UsagePercentageCard
                                            icon="fa-solid fa-signature"
                                            title="Firmas"
                                            total={plan.zapsign_quantity}
                                            used={metrics.length > 0 && metrics[0].signed_documents_zapsign}
                                        />
                                    </div>

                                    <div className="col-12 col-lg-6 item__usage">
                                        <UsagePercentageCard
                                            icon="fa-solid fa-user"
                                            title="Usuarios"
                                            total={plan.user_limit}
                                            used={metrics.length > 0 && metrics[0].registered_users}
                                        />
                                    </div>

                                    <div className="col-12 col-lg-6 item__usage">
                                        <UsagePercentageCard
                                            icon="fa-solid fa-robot"
                                            title="GPT tokens"
                                            total={plan.gpt_tokens_limit}
                                            used={metrics.length > 0 && metrics[0].used_gpt_tokens}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        {
                            company.is_system_admin
                            &&
                            <LogViews />
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default DashboardView;