import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";

import GeneralService from "../../../../services/GeneralService";
import { MessageError } from "../../../../utils/message";
import { cleanData, setDataPeopleOnEdit } from "../../../../reducers/people/reducerPeople";
import { setSelectedTypepeople } from '../../../../reducers/typePeople/reducerTypepeople';
import { extractFieldsTypepeople } from "../../utils/extract";
import { hideLoading, showLoading } from "../../../../reducers/main/loadingReducer";
import { decodeToken } from "../../../../utils/decodeJWT";
import { validateDataForm } from '../../../FillOutForm/utils/extract';

export const useRegisterCustomer = () => {
    const { dataPeople } = useSelector(state => state.peopleSlice);
    const { selectedTypepeople } = useSelector(state => state.typepeopleSlice);
    const history = useHistory()
    const dispatch = useDispatch();
    let { token = "" } = useParams();
    const { type_people_id, name } = decodeToken(token);

    const loadTypePeople = async () => {
        const service = new GeneralService("typepeople/" + type_people_id);
        const typePeople = await service.getRequest();
        dispatch(setSelectedTypepeople({ value: typePeople }));

        const data = {};
        const fieldsData = extractFieldsTypepeople(typePeople);

        fieldsData.map(param => {
            if (!param.required && param.alert_required && !param.is_category) {
                data[param.name] = {
                    value: "",
                    parameterization_id: param.id,
                    ...param
                }
            }
        });

        dispatch(setDataPeopleOnEdit({ value: data }));
    }

    const verifyToken = async () => {
        const service = new GeneralService("people/check-token");
        const res = await service.post({ token });
        if (!res.is_ok) {
            history.replace("/customer/registration-status/error/" + token);
        }
    }

    const saveDataPeople = async (e) => {
        e.preventDefault();
        const service = new GeneralService("people/customer/create/" + token);

        const { listData, omit, isOk } = await validateDataForm(dataPeople);
        if (!omit || !isOk) {
            return false;
        }
        const data_peoples = listData;

        dispatch(showLoading());
        const res = await service.post({ type_peoples_id: selectedTypepeople.id, data_peoples });
        dispatch(hideLoading());
        if (res.is_ok) {
            dispatch(cleanData());
            history.replace("/customer/registration-status/successful/" + token);
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        verifyToken();
        loadTypePeople();
    }, []);

    return {
        name,
        saveDataPeople,
    };
}
