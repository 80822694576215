import { useParams, Link } from "react-router-dom";

import { BackgroundResetPassword } from "../../../../app/components/design/MinimalistBackground";

import NoSuccess from "../../../../assets/no-permited.png";
import { decodeToken } from "../../../../utils/decodeJWT";

const InvalidTokenRegistrationMessage = () => {
    const { token = "" } = useParams();
    const { name = "" } = decodeToken(token);

    return (
        <div className="container-fluid p-0">
            <div className="stack">
                <BackgroundResetPassword />

                <div className="floating-container">
                    <div className="form-style">
                        <div className="row">
                            <img src={NoSuccess} alt="Imagen en Freepik" height={250} />
                        </div>
                        <div className="row">
                            <h4 className="text-center mb-3 text-orange fw-bold">😯Token Inválido</h4>
                            <p><b>Hola {name.split(" ")[0]}</b>💁</p>

                            <p>Lo sientimos, pero el token de seguridad proporcionado no es válido o ha expirado. Por favor, comunícate con el administrador de la empresa para obtener asistencia adicional.</p>
                            <p>Saludos cordiales,
                                <br />
                                El equipo de <b>Sortom</b>.</p>

                        </div>

                        <div className="row mt-2">
                            <div className="col text-center">
                                <Link to="/auth/login" className="btn btn-success text-white d-inline-block rounded-5 py-2 px-3 my-2">
                                    Ir al inicio
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvalidTokenRegistrationMessage;