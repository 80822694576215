

export const extractFieldsTypepeople = (typePeople) => {
    const fields = [];
    typePeople.clusters?.forEach(cluster => {
        cluster.parameterizations?.forEach(param => {
            fields.push({ ...param, is_category: false });
        });
    });
    typePeople.categories?.forEach(category => {
        category.clusters?.forEach(cluster => {
            cluster.parameterizations?.forEach(param => {
                fields.push({ ...param, is_category: true });
            });
        });
    });

    return fields;
}
// --------------
export const extractFieldsCategory = (category) => {
    const fields = [];
    category.clusters?.forEach(cluster => {
        cluster.parameterizationse?.forEach(param => {
            fields.push(param);
        });
    });

    return fields;
}
// ---------------
export const extractDataAndCategories = (data_peoples, fieldsParameterization, typePeople) => {
    let data = {};
    let categories = [];

    data_peoples?.forEach(pData => {
        if (pData.is_category) {
            const indexParameterization = fieldsParameterization.findIndex(p => p.id === pData.parameterization_id && p.is_category);
            const { id, ...other } = fieldsParameterization[indexParameterization];

            if (indexParameterization !== -1) {
                data[fieldsParameterization[indexParameterization].name] = {
                    ...pData,
                    ...other,
                };

                const indexCategory = categories.findIndex(p => p.id === fieldsParameterization[indexParameterization].parent_id);
                if (indexCategory === -1) {
                    categories.push(typePeople.categories.find(f => f.id === fieldsParameterization[indexParameterization].parent_id));
                }
            }
        } else {
            const indexParameterization = fieldsParameterization.findIndex(p => p.id === pData.parameterization_id && !p.is_category);
            const { id, ...other } = fieldsParameterization[indexParameterization];

            if (indexParameterization !== -1) data[fieldsParameterization[indexParameterization].name] = {
                ...pData,
                ...other,
            };
        }
    });

    return { data, categories };
}