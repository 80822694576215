import { Pagination } from "react-bootstrap";

export const CustomPagination = ({ pagination, setPagination }) => {
    return (
        <>
            <div className="row">
                <div className="col-2 ms-auto">
                    <select
                        className="form-select parametrizacion__form-input"
                        value={pagination.page_size}
                        onChange={({ target }) => setPagination({ ...pagination, page_size: target.value })}
                    >
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col abs-center">
                    <Pagination>
                        <Pagination.Prev
                            onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })}
                            disabled={pagination.total_pages === 0 || pagination.page === 0}
                        />

                        <ItemsPagination
                            total={pagination.total_pages}
                            active={pagination.page}
                            onClick={setPagination}
                        />

                        <Pagination.Next
                            onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })}
                            disabled={pagination.total_pages === 0 || pagination.total_pages === pagination.page}
                        />
                    </Pagination>
                </div>
            </div >
        </>
    )
}

const ItemsPagination = ({ total = 0, active, onClick }) => {
    const itemPages = [];

    if (total === 0) {
        return <Pagination.Ellipsis />
    }

    for (let i = 0; i <= total; i++) {
        itemPages.push(<Pagination.Item
            active={active === i}
            onClick={() => onClick(c => ({ ...c, page: i }))}
        >
            {i + 1}
        </Pagination.Item>);
    }

    return itemPages;
}